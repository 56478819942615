/* base styles*/
*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  width: 100%;
  min-width: 320px;
  font-family: 'Roboto',arial,sans-serif;
  background: #fff;
  color: #1f1e1e;
  position: relative;
  font-size: 16px;
  line-height: 1.2;
}

img {
  vertical-align: bottom;
  border: 0;
  max-width: 100%;
}

ol, ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 0;
}

a {
  color: #161515;
  text-decoration: none;
  text-align: center;
}

a:hover {
  color: #fbb339;
}

/* helping adds*/
.clear {
  clear: both;
  font-size: 1px;
  line-height: 1px;
  height: 0;
  overflow: hidden;
}

.cfix:after {
  content: '';
  display: block;
  height: 0;
  font-size: 1px;
  line-height: 1px;
  overflow: hidden;
  clear: both;
}

.hidden-block {
  position: absolute;
  left: -99999em;
  top: -99999em;
}

/* /helping adds*/
/* wraps styling*/
.mbox {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 15px;
}

.mbox:after {
  content: "";
  clear: both;
  font-size: 1px;
  line-height: 1px;
  height: 0;
  overflow: hidden;
  display: block;
}

/* /wraps styling*/
.footer_placeholder {
  height: 200px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #aeb0b2;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #aeb0b2;
  opacity: 1;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #aeb0b2;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #aeb0b2;
}

/*fonts*/
@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Bold.eot");
  src: url("/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Roboto-Bold.woff") format("woff"), url("/fonts/Roboto-Bold.ttf") format("truetype"), url("/fonts/Roboto-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Regular.eot");
  src: url("/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Roboto-Regular.woff") format("woff"), url("/fonts/Roboto-Regular.ttf") format("truetype"), url("/fonts/Roboto-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Bookman";
  src: url("/fonts/BookmanOldStyle.eot");
  src: url("/fonts/BookmanOldStyle.eot?#iefix") format("embedded-opentype"), url("/fonts/BookmanOldStyle.woff") format("woff"), url("/fonts/BookmanOldStyle.ttf") format("truetype"), url("/fonts/BookmanOldStyle.svg#Bookman") format("svg");
  font-weight: 700;
  font-style: italic;
}

/*/fonts*/
/* /base styles */
/* ctext */
.ctext h1, .ctext h2, .ctext h3, .ctext h4, .ctext h5, .ctext h6 {
  line-height: 1.25;
  margin-bottom: 15px;
  font-weight: 700;
}

.ctext h1 {
  font-size: 40px;
}

.ctext h2 {
  font-size: 32px;
}

.ctext h3 {
  font-size: 30px;
}

.ctext h4 {
  font-size: 24px;
}

.ctext h5 {
  font-size: 20px;
}

.ctext h6 {
  font-size: 16px;
}

.ctext p {
  margin-bottom: 10px;
  font-size: 14px;
}

/* /ctext */
/* styles for wp-admin panel */
body.customize-support .wp-panel-show {
  top: 32px;
}

@media screen and (max-width: 782px) {
  body.customize-support .wp-panel-show {
    top: 46px;
  }
  #wpadminbar {
    position: fixed !important;
  }
}

/* /styles for wp-admin panel */
/*End Layout*/
